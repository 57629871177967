// 3rd party packages
import $ from 'jquery';
import 'jquery-migrate';
import 'bootstrap';
import 'slick-carousel';
import 'lightbox2';
import fitvids from 'fitvids';

window.$ = $;
window.jQuery = $;

var backToTopButton;

$(function() {
    fitvids();

    $('.hamburger-toggle').on('click', function() {
        console.log($(this));
        $(this).find('i').toggleClass('bi-list bi-x');
    });

    $('[data-tooltip="true"]').tooltip();

    backToTopButton = document.getElementById("scroll-top");
    window.onscroll = function() { scrollFunction() };
});

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        backToTopButton.style.display = "block";
    } else {
        backToTopButton.style.display = "none";
    }
}